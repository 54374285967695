<template>
  <div class="home-wrapper">
    <!--<div v-auth="'test111'">
      Welcome! v-auth="test111" 显示这个代表权限标签正常开启
    </div>
    <div v-auth="'test'">
      Welcome! v-auth="test" 显示这个代表权限标签正常开启
    </div>-->
    <a-row :gutter="20">
      <a-col :span="6">
        <a-card class="box-card-index">
          <p class="index_title">基地消息</p>
          <div>
            <template>
              <span style="font-weight: 500; font-size: 25px; color: #3f3f3f">{{
                FarmMsg.area
              }}</span>
              <span style="margin-left: 5px">亩</span>
            </template>
            <template>
              <span style="margin-left: 20px">地块总数</span>
              <span
                style="
                  margin-left: 5px;
                  font-weight: 500;
                  font-size: 25px;
                  color: #3f3f3f;
                "
                >{{ FarmMsg.areaNum }}</span
              >
            </template>
          </div>
          <a-divider style="margin-top: 10px" />
          <p style="position: absolute; bottom: 0">
            {{ FarmMsg.organizationName }}
          </p>
        </a-card>
      </a-col>
      <a-col :span="6">
        <a-card class="box-card-index">
          <p class="index_title">设备情况</p>
          <div>
            <template>
              <span style="font-weight: 500; font-size: 25px; color: #3f3f3f">{{
                FarmDeviceMsg.sensorNum
              }}</span>
              <span style="margin-left: 5px">传感器</span>
            </template>
            <template>
              <span
                style="
                  margin-left: 20px;
                  font-weight: 500;
                  font-size: 25px;
                  color: #3f3f3f;
                "
                >{{ FarmDeviceMsg.cameraNum }}</span
              >
              <span style="margin-left: 5px">视频</span>
            </template>
          </div>

          <a-divider style="margin-top: 4rem" />

          <div style="position: absolute; bottom: 0">
            <div class="online_circle">
              <div class="circle"></div>
              <div class="circle"></div>
              <div class="circle"></div>
              <div class="circle"></div>
            </div>
            <div style="margin-bottom: 1em; margin-left: 20px">
              <span>{{ FarmDeviceMsg.onlineNum }}</span>
              <span>台在线</span>
              <span style="margin-left: 30px"></span>
              <a-badge status="warning" />
              <span>{{ FarmDeviceMsg.offlineNum }}</span>
              <span>台离线</span>
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :span="6">
        <a-card class="box-card-index" style="padding-bottom: 0">
          <p class="index_title">基地环境数据</p>
          <div>
            <template>
              <img
                src="../img/welcome/云朵.png"
                style="width: 45px; height: 40px"
              />
              <span
                style="
                  margin-left: 10px;
                  font-weight: 500;
                  font-size: 20px;
                  color: #3f3f3f;
                "
                >{{
                  FarmEnvironmentMsg.airT ? FarmEnvironmentMsg.airT : "-"
                }}°C</span
              >
            </template>
            <template>
              <span style="margin-left: 35px">
                {{ FarmEnvironmentMsg.region }}
              </span>
            </template>
          </div>
          <div id="charCurved" style="padding-top: 10px"></div>
          <p
            style="position: absolute; bottom: 0; left: 10px"
            class="environment_buttom"
          >
            <span>气温:</span>
            <span
              >{{
                FarmEnvironmentMsg.airT ? FarmEnvironmentMsg.airT : "--"
              }}°C</span
            >
            <span style="margin-left: 10px"></span>
            <span>湿度:</span>
            <span
              >{{
                FarmEnvironmentMsg.airH ? FarmEnvironmentMsg.airH : "--"
              }}%</span
            >
            <span style="margin-left: 10px"></span>
            <span>风向:</span>
            <span>{{
              FarmEnvironmentMsg.windDirection
                ? FarmEnvironmentMsg.windDirection
                : "--"
            }}</span>
          </p>
        </a-card>
      </a-col>
      <a-col :span="6">
        <a-card class="box-card-index">
          <p class="index_title">当前种植农产品</p>
          <!-- <a-empty v-if="FarmAgriculturalProductsMsg.length == 0" image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"/> -->
          <div id="char">
            <rb-empty
              v-if="FarmAgriculturalProductsMsg.length == 0"
              background="false"
            />
          </div>
        </a-card>
      </a-col>
    </a-row>
    <div style="margin-top: 20px"></div>
    <a-row :gutter="20">
      <a-col :span="18">
        <a-card class="box-card-middle myfarm_class" title="我的农场">
          <div style="z-index: 99;" class="mapDetail" v-if="markerFlag">
            <div class="close-btn" @click="handleMarker">
              <img src="../img/close.png" alt="">
            </div>
            <div class="name">{{markerInfo.name}}</div>
            <div class="info">{{markerInfo.describe}}</div>
          </div>
          <div style="position: absolute; z-index: 99; right: 10px">
            <a-button class="change_map_button" @click="changeMapStyle(1)"
              >户外图</a-button
            >
            <br />
            <a-button class="change_map_button" @click="changeMapStyle(2)"
              >卫星图</a-button
            >
            <br />
            <a-button class="change_map_button" @click="changeMapStyle(3)"
              >街道图</a-button
            >
          </div>
          <!--display: block;box-sizing: border-box-->
          <div
            style="
              position: relative;
              margin-right: 0px;
              margin-left: 0px;
              height: 30rem;
              z-index: 98;
            "
          >
            <div id="mapCard"></div>
          </div>
        </a-card>
      </a-col>
      <a-col :span="6">
        <a-card class="box-card-middle warning_class" title="预警消息">
          <rb-empty v-if="WarningMsg.length == 0" />
          <!-- <a-empty v-if="WarningMsg.length == 0" image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"/> -->
          <div style="height: 30rem; overflow: auto" class="scroll_bar">
            <a-timeline style="margin-top: 2px">
              <a-timeline-item v-for="item in WarningMsg" :key="item.id">
                <div style="font-size: 10px">
                  {{ item.customMeterAddress +item.customMeterName}}
                  <span style="color:#e63424">{{item.abnormalDescribe}}</span>
                </div>
                <div style="font-size: 10px">
                  异常值: {{item.abnormalValue+item.meterCollectItemUnit}}
                </div>
                <div style="font-size: 3px; color: #909399">
                  {{ item.time | dateFormatFull }}
                </div>
              </a-timeline-item>
            </a-timeline>
          </div>
        </a-card>
      </a-col>
    </a-row>

    <!--<div>
        Welcome! v-auth=""
    </div>-->
    <!--<div v-auth="'test'">
        Welcome! v-auth="test" 显示这个代表权限标签正常开启
    </div>
    <div v-auth="'organizational_management'">
        v-auth="organizational_management"  显示这个代表权限标签正常开启
    </div>-->
  </div>
</template>

<script>
import { Chart } from "@antv/g2";
import { Mapbox } from "@antv/l7-maps";
import { Scene, PointLayer, Marker } from "@antv/l7";
import { DrawPolygon, DrawControl } from "@antv/l7-draw";
import { GaodeMapV2 } from "@antv/l7-maps";

export default {
  name: "welcome",
  data() {
    return {
      farmParcelMap: {
        lng: "",
        lat: "",
        ponits: [],
        areaMarkers: [],
      },
      timer: "timer",
      chartObj: [],
      charCurvedObj: [],
      WarningMsg: [],
      FarmAgriculturalProductsMsg: [],
      FarmEnvironmentMsg: {
        region: "",
        airT: "",
        airH: "",
        windDirection: "",
        airTAndAirHList: [],
      },
      FarmDeviceMsg: {
        sensorNum: "",
        cameraNum: "",
        onlineNum: "",
        offlineNum: "",
      },
      FarmMsg: {
        area: "",
        areaNum: "",
        organizationName: "",
      },
      scene: null,
      markerInfo: {},
      markerFlag: false
    };
  },
  watch: {
    FarmAgriculturalProductsMsg: function (val) {
      if (this.FarmAgriculturalProductsMsg) {
        this.chartObj.data(this.FarmAgriculturalProductsMsg);
        this.chartObj.render();
      }
    },
    FarmEnvironmentMsg: function (val) {
      if (this.FarmEnvironmentMsg.airTAndAirHList) {
        this.charCurvedObj.data(this.FarmEnvironmentMsg.airTAndAirHList);
        this.charCurvedObj.render();
      }
    },
  },
  mounted() {
    this.initWarningMsg();
    this.initFarmDeviceMsg();
    this.initFarmMsg();
    this.timer = setInterval(this.reloadData, 30000);
  },
  created() {
    this.initMapMsg().then(() => {
      this.initMap();
    });
    this.initChar().then(() => {
      this.initFarmAgriculturalProductsMsg();
    });
    this.initCharCurved().then(() => {
      this.initFarmEnvironmentMsg();
    });
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    handleMarker(){
      this.markerFlag = false
    },
    changeMapStyle(num) {
      if (num === 1) {
        this.scene.setMapStyle("mapbox://styles/mapbox/outdoors-v11");
      } else if (num === 2) {
        this.scene.setMapStyle("mapbox://styles/mapbox/satellite-streets-v11");
      } else if (num === 3) {
        this.scene.setMapStyle("mapbox://styles/mapbox/streets-v11");
      }
    },
    initMapMsg() {
      return new Promise((resolve, reject) => {
        this.http.welcome.getFarmParcel().then((response) => {
          if (response.data.status == 200) {
            this.farmParcelMap.lng = response.data.data.lng;
            this.farmParcelMap.lat = response.data.data.lat;
            this.farmParcelMap.ponits = response.data.data.ponits;
            this.farmParcelMap.areaMarkers = response.data.data.areaMarkers;
          }
          resolve(this.farmParcelMap);
        });
      });
    },
    initMap() {
      let that = this
      this.$nextTick(() => {
        let scene = this.scene;
        let areaMarkers = this.farmParcelMap.areaMarkers;
        let lng = this.farmParcelMap.lng;
        let lat = this.farmParcelMap.lat;
        let ponits = this.farmParcelMap.ponits;
        const style = {
          normal: {
            polygon: {
              type: "PolygonLayer",
              shape: "fill",
              color: "#1a83ff",
              style: {
                opacity: 0.1,
                stroke: "#2fa6ff",
                strokeWidth: 1,
                strokeOpacity: 1,
                lineType: "solid",
                dashArray: [2, 2],
              },
            },
          },
        };
        const polygon = {
          type: "FeatureCollection",
          features: [],
        };
        for (let ponit of ponits) {
          polygon.features.push({
            type: "Feature",
            properties: {},
            geometry: {
              type: "Polygon",
              coordinates: JSON.parse(ponit),
            },
          });
        }
        scene = new Scene({
          id: "mapCard",
          logoVisible: false,
          map: new Mapbox({
            style: "mapbox://styles/mapbox/outdoors-v11",
            center: [lng, lat],
            pitch: 10,
            zoom: 14.95,
            rotation: 19.313180925794313,
            token:
              "pk.eyJ1IjoiZGlhbmF5IiwiYSI6ImNrdDNvazJpdTB2ejgydXVsdW50OWgyMTMifQ.Qax_apbCk2-XhMlwmzB6kA",
          }),
        });
        scene.on("loaded", () => {
            if(polygon.features.length>0){
                createDraw();
            }
          //createDrawControl();
          addMarkers();
        });
        this.scene = scene;
        function createDrawControl() {
          const drawControl = new DrawControl(scene, {
            position: "topleft",
            layout: "horizontal", // horizontal vertical
            controls: {
              point: true,
              polygon: true,
              line: true,
              circle: true,
              rect: true,
              delete: true,
            },
          });
          scene.addControl(drawControl);
        }
        function createDraw() {
          new DrawPolygon(scene, {
            data: polygon,
            selectEnable: false,
            style: style,
          });
        }
        function addMarkers() {
          for (let point of areaMarkers) {
            const el = document.createElement("label");
            el.className = "labelclass";
            el.textContent = point.name;
            const marker = new Marker({
              element: el,
            }).setLnglat({ lng: point.lng * 1, lat: point.lat });
            // 在标注上添加事件
            marker.setExtData(point)
            marker.on('mouseover', (e) => {
              that.markerFlag = true
              that.markerInfo = e.data
              // console.log(e)
            })
            // mousemove
            // click
            // mousedown
            // mouseup
            // dblclick
            // contextmenu
            // mouseover
            // mouseout
            scene.addMarker(marker);
          }
        }
      });
    },
    reloadData() {
      this.initWarningMsg();
      this.initFarmDeviceMsg();
      this.initFarmMsg();
      this.initFarmAgriculturalProductsMsg();
      this.initFarmEnvironmentMsg();
    },
    initChar() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.$nextTick(() => {
            const data = this.FarmAgriculturalProductsMsg;
            this.chartObj = new Chart({
              container: "char",
              autoFit: true,
              height: 150,
            });
            this.chartObj.coordinate("theta", {
              radius: 0.75,
            });
            //this.chartObj.data(data);
            this.chartObj.scale("percent", {
              formatter: (val) => {
                val = val * 100 + "%";
                return val;
              },
            });
            this.chartObj.legend(false);
            this.chartObj.tooltip({
              showTitle: false,
              showMarkers: false,
            });
            this.chartObj
              .interval()
              .adjust("stack")
              .position("percent")
              .color("collectItem")
              .label("percent", {
                content: (data) => {
                  return `${data.collectItem}`;
                },
              })
              .adjust("stack");
            this.chartObj.interaction("element-active");
            //this.chartObj.render();
            resolve(this.chartObj);
          });
        }, 500);
      });
    },
    initCharCurved() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.$nextTick(() => {
            const data = this.FarmEnvironmentMsg.airTAndAirHList;
            this.charCurvedObj = new Chart({
              container: "charCurved",
              autoFit: true,
              height: 50,
              padding: [10, 0, 0, 0], // 上，右，下，左
            });
            //this.charCurvedObj.data(data);
            this.charCurvedObj.scale({
              time: {
                range: [0, 1],
              },
              value: {
                formatter: (val) => {
                  val = val;
                  return val;
                },
              },
              collectItem: {
                formatter: (val) => {
                  val = val === "airT" ? "温度" : "湿度";
                  return val;
                },
              },
            });
            /*this.charCurvedObj.scale('value', {
                                formatter: (val) => {
                                    val = val  + '°C';
                                    return val;
                                },
                            });*/
            this.charCurvedObj.tooltip({
              showCrosshairs: true,
              shared: true,
            });
            this.charCurvedObj.axis("value", false);
            this.charCurvedObj.axis("time", false);
            this.charCurvedObj.legend(false);
            this.charCurvedObj
              .line()
              .adjust("stack")
              .position("time*value")
              .color("collectItem")
              .shape("smooth");
            //this.charCurvedObj.render();
            resolve(this.charCurvedObj);
          });
        }, 500);
      });
    },
    async initWarningMsg() {
      const { data: WarningMsg } = await this.http.welcome.getWarningMsg();
      if (WarningMsg.status == 200) {
        this.WarningMsg = WarningMsg.data;
      }
    },
    async initFarmAgriculturalProductsMsg() {
      const { data: FarmAgriculturalProductsMsg } =
        await this.http.welcome.getFarmAgriculturalProductsMsg();
      if (FarmAgriculturalProductsMsg.status == 200) {
        this.FarmAgriculturalProductsMsg = FarmAgriculturalProductsMsg.data;
      }
    },
    async initFarmEnvironmentMsg() {
      const { data: FarmEnvironmentMsg } =
        await this.http.welcome.getFarmEnvironmentMsg();
      if (FarmEnvironmentMsg.status == 200) {
        this.FarmEnvironmentMsg = FarmEnvironmentMsg.data;
      }
    },
    async initFarmDeviceMsg() {
      const { data: FarmDeviceMsg } =
        await this.http.welcome.getFarmDeviceMsg();
      if (FarmDeviceMsg.status == 200) {
        this.FarmDeviceMsg = FarmDeviceMsg.data;
      }
    },
    async initFarmMsg() {
      const { data: FarmMsg } = await this.http.welcome.getFarmMsg();
      if (FarmMsg.status == 200) {
        this.FarmMsg = FarmMsg.data;
      }
    },
    async init() {
      const { data: FarmAgriculturalProductsMsg } =
        await this.http.welcome.getFarmAgriculturalProductsMsg();
      if (FarmAgriculturalProductsMsg.status == 200) {
        this.FarmAgriculturalProductsMsg = FarmAgriculturalProductsMsg.data;
      }
      const { data: FarmDeviceMsg } =
        await this.http.welcome.getFarmDeviceMsg();
      if (FarmDeviceMsg.status == 200) {
        this.FarmDeviceMsg = FarmDeviceMsg.data;
      }
      const { data: FarmEnvironmentMsg } =
        await this.http.welcome.getFarmEnvironmentMsg();
      if (FarmEnvironmentMsg.status == 200) {
        this.FarmEnvironmentMsg = FarmEnvironmentMsg.data;
      }
      const { data: FarmMsg } = await this.http.welcome.getFarmMsg();
      if (FarmMsg.status == 200) {
        this.FarmMsg = FarmMsg.data;
      }
      const { data: WarningMsg } = await this.http.welcome.getWarningMsg();
      if (WarningMsg.status == 200) {
        this.WarningMsg = WarningMsg.data;
      }
    },
  },
};
</script>

<style scoped>
.change_map_button {
  border-radius: 1px;
}
#charCurved {
}
#mapCard >>> .labelclass {
  padding: 0.05rem;
  color: #ffffff;
  border-radius: 5px;
  background-color: #ffab26;
}

.online_circle {
  position: relative;
  top: 0.4em;
}

.circle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #52c41a;
}

.circle:nth-child(1) {
  animation: anim 2s linear infinite;
}

.circle:nth-child(2) {
  animation: anim 2s linear 0.5s infinite;
}

.circle:nth-child(3) {
  animation: anim 3s linear 0.8s infinite;
}

@keyframes anim {
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(5.5);
  }
}

.environment_buttom span {
  font-size: 10px;
}

.box-card-index {
  min-width: 13rem;
  height: 13rem;
}

.warning_class {
  min-width: 13rem;
}

.box-card-middle {
  height: 35rem;
}

.home-wrapper {
  padding: 20px;
}

.warning_class >>> .ant-card-body {
  padding: 14px;
}

.myfarm_class >>> .ant-card-body {
  padding: 10px;
}

.index_title {
  font-size: 14px;
}

/*滚动条整体样式*/
.scroll_bar::-webkit-scrollbar {
  width: 2px; /*高宽分别对应横竖滚动条的尺寸*/
}

.scroll_bar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #1890ff;
}

.scroll_bar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
}
.mapDetail{
  position: absolute;
  top: 99;
  background: #fff;
  bottom: 28px;
  right: 24px;
  padding: 14px 24px;
  font-size: 14px;
  border-radius: 8px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mapDetail .name{
  font-weight: bold;
}
  .close-btn{
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
  .close-btn img{
    width: 24px;
    height: 24px;
  }
</style>
